<template>
  <div class="study">
    <vocabulary />
  </div>
</template>

<script>
import Vocabulary from '@/components/study/vocabulary'

export default {
  components: { Vocabulary }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.study {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
}
</style>

<template>
  <div class="vocabulary">
    <div class="vocabulary__item" v-if="user.occupation">
      <h2 class="vocabulary__item__title">Bài thi</h2>
      <div class="vocabulary__item__exams" @click="$router.push({ name: 'Exam' })">
        <img class="vocabulary__item__exams__img" src="@/assets/img/idea.svg">
        <div class="vocabulary__item__exams__cover">
          <p class="vocabulary__item__exams__cover__title">Bài thi kiểm<br>định kỹ năng</p>
          <p class="vocabulary__item__exams__cover__title--start">Bắt đầu</p>
        </div>
      </div>
    </div>
    <div class="vocabulary__item">
      <h2 class="vocabulary__item__title">Từ vựng</h2>
      <ul class="vocabulary__item__list">
        <li v-for="level in levels" class="vocabulary__item__list__area" :key=level
        @click="$router.push({ name: 'StudyVocabulary', query: { level: level } })">
          <div :class="'vocabulary__item__list__area__cover--' + level">
            <p class="vocabulary__item__list__area__cover__name">{{ level }}</p>
            <p class="vocabulary__item__list__area__cover__name--sub">Từ vựng</p>
            <img class="vocabulary__item__list__area__cover__img" src="@/assets/img/left-arrow.svg">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 日本語レベル
      // レベルN3, N2は一旦非表示に。将来的に条件を満たしたユーザーにのみ表示とする。
      levels: ['N5', 'N4'] //, 'N3', 'N2']
    }
  },
  computed: {
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['user/user']
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.vocabulary {
  &__item {
    margin: 0 0 22px;
    &__title {
      margin: 22px 0 0;
      color: #393939;
      font-size: 20px;
      font-weight: bold;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__exams {
      position: relative;
      height: 147px;
      margin-top: 10px;
      border-radius: 10px;
      background-color: #d1d1d1;
      &__img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
      &__cover {
        display: flex;
        justify-content: center;
        flex-direction:column;
        position: relative;
        height: 100%;
        padding: 20px;
        &__title {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
          line-height: 27px;
          color: #ffffff;
          &--start {
            @extend .vocabulary__item__exams__cover__title;
            color: #fce150;
          }
        }
      }
    }
    &__list {
      padding: 0;
      &__area {
        margin-top: 10px;
        list-style: none;
        &__cover {
          display: flex;
          align-items: center;
          height: 63px;
          width: 100%;
          padding: 0 15px;
          border-radius: 10px;
          color: #FFFFFF;
          &__name {
            margin: 0 8px 0 0;
            font-size: 30px;
            line-height: 41px;
            &--sub {
              @extend .vocabulary__item__list__area__cover__name;
              flex: 1;
              font-size: 15px;
              line-height: 20px;
            }
          }
          &__img {
            height: 50%
          }
          &--N5 {
            @extend .vocabulary__item__list__area__cover;
            background-color: #f3dc5b;
          }
          &--N4 {
            @extend .vocabulary__item__list__area__cover;
            background-color: #f38b8b;
          }
          &--N3 {
            @extend .vocabulary__item__list__area__cover;
            background-color: #5e88ea;
          }
          &--N2 {
            @extend .vocabulary__item__list__area__cover;
            background-color: #87738a;
          }
        }
      }
    }
  }
}
</style>
